import { action } from 'core';
import ClusterService from 'model/cluster/ClusterService';
import { IScreen } from 'model/screen/ScreenDataModel';
import ScreenService from 'model/screen/ScreenService';
import { ScreenStatusMapReq, ScreenStatusMapRes } from './ScreenStatusMapMdl';


const listScreenForMapImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    result.screenList = await ScreenService.listScreenForMap();
};

const listClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    result.clusterList = await ClusterService.list();
};


const addClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    const { screenStatusMapMdl } = thunkAPI.getState() as any;
    result.cluster = await ClusterService.add({ name: request.name, screenIds: screenStatusMapMdl.screenList.filter((s) => s.selected).map((s) => s.id) });
};

const saveClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    const { screenStatusMapMdl } = thunkAPI.getState() as any;
    const oldCluster = await ClusterService.get(request.clusterId);
    const updatedList: IScreen[] = screenStatusMapMdl.screenList.filter(screen => screen.selected === true);
    const idsToUpdate = updatedList.map(screen => screen.id);
    await ClusterService.updateScreen(oldCluster, idsToUpdate);
};

const getClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    result.cluster = await ClusterService.get(request.clusterId);
};

const ScreenStatusMapCtrl = {
    listScreenForMap: action<ScreenStatusMapReq, ScreenStatusMapRes>(listScreenForMapImpl, 'ScreenStatusMapCtrl/listScreenForMap'),
    listCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(listClusterImpl, 'ScreenStatusMapCtrl/listCluster'),
    addCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(addClusterImpl, 'ScreenStatusMapCtrl/addCluster'),
    saveCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(saveClusterImpl, 'ScreenStatusMapCtrl/saveCluster'),
    getCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(getClusterImpl, 'ScreenStatusMapCtrl/getCluster'),
};

export default ScreenStatusMapCtrl;
