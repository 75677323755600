import { createSelector, createSlice, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import { ICluster } from 'model/cluster/ClusterDataModel';
import { IMetadata } from 'model/metadata/MetadataDataModel';
import { IScreen, IScreenQuery } from 'model/screen/ScreenDataModel';
import FindScreenCtrl from './FindScreenCtrl';

export interface FindScreenReq extends IRequest {
    dataForm?: any;
    currentPage?: number;
    screenId?: string;
    screenHid?: string;
}

export interface FindScreenRes extends IResult {
    screenList?: IScreen[];
    query?: IScreenQuery;
    screen?: IScreen;
    screenHistory?: IScreen[];
    metadata?: IMetadata;
    clusterList?: ICluster[];
}

const initialState = {
    result: {} as FindScreenRes,
    screenList: [] as IScreen[],
    selectedScreenList: [] as IScreen[],
    screen: {} as IScreen,
    screenHistory: [] as IScreen[],
    query: {} as IScreenQuery,
    metadata: {} as IMetadata,
    clusterList: [] as ICluster[],
};

type FindScreenType = typeof initialState;

const SliceFindScreen = createSlice({
    name: 'FindScreenMdl',
    initialState,
    reducers: {
        init: (state) => {
            return { ...initialState };
        },
        selectScreen: (state, action: PayloadAction<IScreen>) => {
            if (!state.selectedScreenList.some(screen => screen.id === action.payload.id)) {
                state.selectedScreenList.push(action.payload);
            }
        },
        removeScreen: (state, action: PayloadAction<IScreen>) => {
            state.selectedScreenList = state.selectedScreenList.filter(screen => screen.id !== action.payload.id);
        },
        clearAll: (state) => {
            state.selectedScreenList = [];
        },
        selectAll: (state) => {
            state.selectedScreenList = [
                ...state.selectedScreenList,
                ...state.screenList.filter(screen => !state.selectedScreenList.some(selectedScreen => selectedScreen.id === screen.id))
            ];
        },
    },
    extraReducers(builder) {
        builder
            .addCase(FindScreenCtrl.findScreen.fulfilled, (state, action) => {
                state.result = action.payload;
                state.screenList = action.payload.screenList;
                state.query = action.payload.query;
            })
            .addCase(FindScreenCtrl.getScreen.fulfilled, (state, action) => {
                state.result = action.payload;
                state.screen = action.payload.screen;
                state.screenHistory = action.payload.screenHistory;
            })
            .addCase(FindScreenCtrl.getScreenByHid.fulfilled, (state, action) => {
                state.result = action.payload;
                state.screen = action.payload.screen;
            })
            .addCase(FindScreenCtrl.init.fulfilled, (state, action) => {
                state.result = action.payload;
                state.metadata = action.payload.metadata;
                state.clusterList = action.payload.clusterList;
            })
            .addCase(FindScreenCtrl.exportScreen.fulfilled, (state, action) => {
                state.result = action.payload;
            })
            .addMatcher(isPending(FindScreenCtrl.findScreen, FindScreenCtrl.init, FindScreenCtrl.getScreen, FindScreenCtrl.getScreenByHid, FindScreenCtrl.exportScreen,), (state) => {
                state.result = {} as FindScreenRes;
            })
            .addMatcher(isRejected(FindScreenCtrl.findScreen, FindScreenCtrl.init, FindScreenCtrl.getScreen, FindScreenCtrl.getScreenByHid, FindScreenCtrl.exportScreen), (state) => {
                state.result = { rid: 'erreur' } as FindScreenRes;
            });
    },
});

export const FindScreenMdl = SliceFindScreen.actions;

const findScreenMdlSelector = (state) => state.findScreenMdl;
export const resultSelector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.result);
export const screenListSelector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.screenList);
export const selectedScreenListSelector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.selectedScreenList);
export const screenSeclector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.screen);
export const screenHistorySeclector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.screenHistory);
export const metadataSelector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.metadata);
export const clusterListSelector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.clusterList);

export default SliceFindScreen.reducer;
