import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { IScreen } from './ScreenDataModel';
import ScreenMapper from './ScreenMapper';



const listScreenForMap = async (): Promise<IScreen[]> => {
    const response = await axios.get(`${API_URL}/screens`);
    const list = response.data?.rows?.map((screen: IScreen) => {
        return { ...screen, lat: +screen.lat, lng: +screen.lng };
    });
    return ScreenMapper.applyMarkerOffset(list);
};


const get = async (screenId): Promise<IScreen> => {
    const response = await axios.get(`${API_URL}/screens/${screenId}`);
    return response.data;
};
const getByHid = async (id, hid): Promise<IScreen> => {
    const response = await axios.get(`${API_URL}/screens/${id}/history/${hid}`);
    const screen = response.data;
    const split = screen.id.split('@');
    return { ...screen, hid: screen.id, updatedAt: split[1], id: split[0] };
};


const listScreenHistory = async (screenId): Promise<IScreen[]> => {
    const response = await axios.get(`${API_URL}/screens/${screenId}/history`);
    const list = response.data?.map((screen) => {
        const split = screen.id.split('@');
        return { ...screen, hid: screen.id, updatedAt: split[1], id: +split[0] };
    });
    return list;
};

const find = async (screenQuery, currentPage: number) => {
    const response = await axios.post(`${API_URL}/screens`, screenQuery);
    const list = response.data?.rows?.map((screen: IScreen) => {
        return { ...screen, lat: +screen.lat, lng: +screen.lng };
    });
    return list;
};

const ScreenService = {
    listScreenForMap,
    get,
    getByHid,
    find,
    listScreenHistory,
};

export default ScreenService;