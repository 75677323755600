import { InfoWindow } from '@vis.gl/react-google-maps';
import { Space } from 'antd';
import { RowSpacing } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import { useI18n } from 'core';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ScreenStatusMapMdl } from '../ScreenStatusMapMdl';

export const Label = styled.div`
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;
`;
export const Value = styled.div`
    font-weight: 300;
    font-size: 18px;
`;

const InfoPopup = ({ screen, close }) => {
    const { i18n } = useI18n();
    const dispatch = useDispatch();
    const select = () => {
        dispatch(ScreenStatusMapMdl.selectScreen(screen));
        close();
    };
    const unselect = () => {
        dispatch(ScreenStatusMapMdl.unselectScreen(screen));
        close();
    };
    return (
        <InfoWindow position={screen} headerDisabled shouldFocus={true} style={{ padding: '0' }}>
            <div style={{ color: '#fff', fontSize: '18px', padding: '5px', backgroundColor: screen.status === 'online' ? '#7209B7' : 'red' }}> {screen.status}</div>
            <div>
                <Label>{i18n('id')}</Label>
                <Value>{screen.id}</Value>
                <Label>{i18n('zipcode')}</Label>
                <Value>{screen.zipcode}</Value>
                <Label> {i18n('status_last')}</Label>
                <Value> {screen.status_last}</Value>
            </div>
            <RowSpacing />
            <div>
                <Space>
                    <PrimaryOutlinedButton name="close" action={close} />
                    {screen.selected && <PrimaryFilledButton name="unselect" action={unselect} />}
                    {!screen.selected && <PrimaryFilledButton name="select" action={select} />}
                </Space>
            </div>
        </InfoWindow>
    );
};

export default InfoPopup;
