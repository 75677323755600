import { createSelector, createSlice, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import { ICampaign, ICampaignQuery } from 'model/campaign/CampaignDataModel';
import FindCampaignCtrlCtrl from './FindCampaignCtrl';

export interface FindCampaignReq extends IRequest {
    dataForm?: any;
    currentPage?: number;
    campaignId?: string;
}

export interface FindCampaignRes extends IResult {
    campaignList?: ICampaign[];
    campaign?: ICampaign;
    query?: ICampaignQuery;
    reportData?: any[];
}

const initialState = {
    result: {} as FindCampaignRes,
    campaignList: [] as ICampaign[],
    campaign: {} as ICampaign,
    selectedCampaignList: [] as ICampaign[],
    query: {} as ICampaignQuery,
    reportData: [] as any[],
};

type FindCampaignType = typeof initialState;

const SliceFindCampaign = createSlice({
    name: 'FindCampaignMdl',
    initialState,
    reducers: {
        init: (state) => {
            return { ...initialState };
        },
        selectCampaign: (state, action: PayloadAction<ICampaign>) => {
            if (!state.selectedCampaignList.some(campaign => campaign.id === action.payload.id)) {
                state.selectedCampaignList.push(action.payload);
            }
        },
        removeCampaign: (state, action: PayloadAction<ICampaign>) => {
            state.selectedCampaignList = state.selectedCampaignList.filter(campaign => campaign.id !== action.payload.id);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(FindCampaignCtrlCtrl.getCampaign.fulfilled, (state, action) => {
                state.result = action.payload;
                state.campaign = action.payload.campaign;
            })
            .addCase(FindCampaignCtrlCtrl.findCampaign.fulfilled, (state, action) => {
                state.result = action.payload;
                state.campaignList = action.payload.campaignList;
                state.query = action.payload.query;
            })
            .addCase(FindCampaignCtrlCtrl.viewReport.fulfilled, (state, action) => {
                state.result = action.payload;
                state.reportData = action.payload.reportData;
            })
            .addMatcher(isPending(FindCampaignCtrlCtrl.getCampaign, FindCampaignCtrlCtrl.findCampaign, FindCampaignCtrlCtrl.viewReport), (state) => {
                state.result = {} as FindCampaignRes;
            })
            .addMatcher(isRejected(FindCampaignCtrlCtrl.getCampaign, FindCampaignCtrlCtrl.findCampaign, FindCampaignCtrlCtrl.viewReport), (state) => {
                state.result = { rid: 'erreur' } as FindCampaignRes;
            });
    },
});

export const FindCampaignMdl = SliceFindCampaign.actions;

const findCampaignMdlSelector = (state) => state.findCampaignMdl;
export const resultSelector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.result);
export const campaignListSelector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.campaignList);
export const selectedCampaignListSeclector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.selectedCampaignList);
export const campaignSelector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.campaign);
export const reportDataSelector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.reportData);

export default SliceFindCampaign.reducer;
