import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import { PrimaryFilledPanel } from 'component';
import InTableViewAction from 'component/action/InTableViewAction';
import FilledButton from 'component/button/FilledButton';
import { STable } from 'component/table/styles';
import { createSorter } from 'component/table/tableUtils';
import { useAppDispatch } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FindScreenMdl, screenListSelector } from '../FindScreenMdl';

const ScreenTable = ({ viewScreen }) => {
    const dispatch = useAppDispatch();
    const screenList = useSelector(screenListSelector);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

    const selectScreen = (screen) => {
        dispatch(FindScreenMdl.selectScreen(screen));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: createSorter('name'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: createSorter('status'),
        },
        {
            title: 'Zipcode',
            dataIndex: 'zipcode',
            key: 'zipcode',
            sorter: createSorter('zipcode'),
        },
        {
            title: 'Status Last',
            dataIndex: 'status_last',
            key: 'status_last',
            sorter: createSorter('status_last'),
            width: 200,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space>
                    <InTableViewAction name="viewScreen" icon={<EyeOutlined />} action={() => viewScreen(record)} />
                    <InTableViewAction name="selectScreen" icon={<CheckOutlined />} action={() => selectScreen(record)} />
                </Space>
            ),
        },
    ];

    const length = () => {
        return screenList ? screenList.length : 0;
    };

    const selectAll = () => {
        dispatch(FindScreenMdl.selectAll());
    };
    return (
        <Badge.Ribbon text={length()} color="red">
            <PrimaryFilledPanel title="screen.resultList">
                <div style={{ textAlign: 'right', padding: '10px 0' }}>
                    <Space>
                        <FilledButton action={selectAll} name="selectAll" inactive={screenList?.length > 0 ? null : 'No screens'} />
                    </Space>
                </div>
                <STable
                    dataSource={screenList}
                    rowKey="id"
                    columns={columns}
                    pagination={{
                        ...pagination,
                        total: screenList?.length,
                        showSizeChanger: false, // Disable changing page size
                    }}
                    onChange={handleTableChange}
                    bordered //
                />
            </PrimaryFilledPanel>
        </Badge.Ribbon>
    );
};

export default ScreenTable;
