import { InfoWindow } from '@vis.gl/react-google-maps';
import { Space } from 'antd';
import { RowSpacing } from 'component';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import { useI18n } from 'core';
import styled from 'styled-components';

export const Label = styled.div`
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;
`;
export const Value = styled.div`
    font-weight: 300;
    font-size: 18px;
`;

const InfoPopup = ({ screen, close }) => {
    const { i18n } = useI18n();

    return (
        <InfoWindow position={screen} headerDisabled shouldFocus={true} style={{ padding: '0' }}>
            <div style={{ color: '#fff', fontSize: '18px', padding: '5px', backgroundColor: screen.status === 'online' ? '#7209B7' : 'red' }}> {screen.status}</div>
            <div>
                <Label>{i18n('id')}</Label>
                <Value>{screen.id}</Value>
                <Label>{i18n('zipcode')}</Label>
                <Value>{screen.zipcode}</Value>
                <Label> {i18n('status_last')}</Label>
                <Value> {screen.status_last}</Value>
            </div>
            <RowSpacing />
            <div>
                <Space>
                    <PrimaryOutlinedButton name="close" action={close} />
                </Space>
            </div>
        </InfoWindow>
    );
};

export default InfoPopup;
