import { Map, Marker } from '@vis.gl/react-google-maps';
import { IScreen } from 'model/screen/ScreenDataModel';
import { useCallback, useEffect, useState } from 'react';
import InfoPopup from './InfoPopup';

const SharedClusterGoogleMaps = ({ screenList }: { screenList: any[] }) => {
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [markersForScreen, setMarkersForScreen] = useState([]);

    const icons = {
        ON: 'https://maps.gstatic.com/mapfiles/ms2/micons/purple-dot.png',
        OFF: 'https://maps.gstatic.com/mapfiles/ms2/micons/pink-dot.png',
    };

    useEffect(() => {
        if (screenList.length > 0) {
            const markerList = screenList.map((screen: IScreen) => {
                console.log(screen);
                return (
                    <Marker //
                        key={screen.id}
                        position={{ lat: +screen.lat, lng: +screen.lng }}
                        onClick={() => setSelectedScreen(screen)}
                        icon={icons[screen.status === 'online' ? 'ON' : 'OFF']}
                    />
                );
            });
            setMarkersForScreen(markerList);
        }
    }, [screenList]);

    const getMarkerList = useCallback(() => {
        return markersForScreen;
    }, [markersForScreen]);

    return (
        <Map //
            style={{ width: '100%', height: '100vh' }}
            defaultCenter={{ lat: 50.8, lng: 4.4 }}
            defaultZoom={10}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
        >
            {selectedScreen && <InfoPopup screen={selectedScreen} close={() => setSelectedScreen(null)} />}
            {getMarkerList()}
        </Map>
    );
};

export default SharedClusterGoogleMaps;
