import { Col, Row } from 'antd';
import { Section } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import { useExecute } from 'core';
import { useEffect, useState } from 'react';
import ScreenDetailDialog from './element/ScreenDetailDialog';
import ScreenFilter from './element/ScreenFilter';
import ScreenTable from './element/ScreenTable';
import SelectedScreenTable from './element/SelectedScreenTable';
import FindScreenCtrl from './FindScreenCtrl';

const FindScreenView = () => {
    const [visible, setVisible] = useState(false);
    const [screenId, setScreenId] = useState(false);
    const { execute } = useExecute();
    const viewScreen = (screen) => {
        setScreenId(screen.id);
        setVisible(true);
    };
    useEffect(() => {
        execute(FindScreenCtrl.init);
    }, []);
    //

    return (
        <Section>
            <Row gutter={[20, 20]}>
                <Col span="24">
                    <PrimaryOutlinedPanel title="screen.filter">
                        <ScreenFilter />
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="16">
                    <ScreenTable viewScreen={viewScreen} />
                </Col>
                <Col span="8">
                    <SelectedScreenTable viewScreen={viewScreen} />
                </Col>
            </Row>
            <Row>
                <Col span="4" offset="20">
                    <ScreenDetailDialog visible={visible} setVisible={setVisible} screenId={screenId} />
                </Col>
            </Row>
        </Section>
    );
};

export default FindScreenView;
