import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { action } from 'core';
import ClusterService from 'model/cluster/ClusterService';
import MetadataService from 'model/metadata/MetadataService';
import ScreenService from 'model/screen/ScreenService';
import { FindScreenReq, FindScreenRes } from './FindScreenMdl';

const findScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    const query = {
        criteriaIds: request.dataForm.criteriaIds,
        name: request.dataForm.name,
        zipcode: request.dataForm.zipcode,
        status: request.dataForm.status,
        clusterIds: request.dataForm.clusterIds,
        playerFilters: Object.entries(request.dataForm)
            .filter(([key]) => !['name', 'zipcode', 'status', 'criteriaIds', 'clusterIds'].includes(key))
            .map(([key, value]) => ({
                key,
                value
            }))
    };

    result.screenList = await ScreenService.find(query, request.currentPage);
};

const getScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    result.screen = await ScreenService.get(request.screenId);
    result.screenHistory = await ScreenService.listScreenHistory(request.screenId);
};

const getScreenByHidImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    result.screen = await ScreenService.getByHid(request.screenId, request.screenHid);
};


const initImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    result.metadata = await MetadataService.get();
    result.clusterList = await ClusterService.list();
};

const exportScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    const { findScreenMdl } = thunkAPI.getState() as any;
    const screenIds = findScreenMdl.selectedScreenList.map((screen) => screen.id);
    try {
        const response = await axios.post(`${API_URL}/screens-export`, { ...request.dataForm, screenIds }, {
            responseType: 'blob',
            headers: {
                'Accept': 'application/csv; charset=utf-8',
            },
        });


        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });


        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        link.setAttribute('download', `screen_export-${timestamp}.csv`);

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

    } catch (error) {
        console.error('Error downloading CSV:', error);
    }
};


const FindScreenCtrl = {
    findScreen: action<FindScreenReq, FindScreenRes>(findScreenImpl, 'FindScreenCtrlCtrl/findScreen'),
    getScreen: action<FindScreenReq, FindScreenRes>(getScreenImpl, 'FindScreenCtrlCtrl/getScreen'),
    init: action<FindScreenReq, FindScreenRes>(initImpl, 'FindScreenCtrlCtrl/init'),
    exportScreen: action<FindScreenReq, FindScreenRes>(exportScreenImpl, 'FindScreenCtrlCtrl/exportScreen'),
    getScreenByHid: action<FindScreenReq, FindScreenRes>(getScreenByHidImpl, 'FindScreenCtrlCtrl/getScreenByHid'),
};

export default FindScreenCtrl;
