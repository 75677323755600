import { action } from 'core';
import CampaignService from 'model/campaign/CampaignService';
import { FindCampaignReq, FindCampaignRes } from './FindCampaignMdl';

const getCampaignImpl = async (request: FindCampaignReq, result: FindCampaignRes, thunkAPI) => {
    result.campaign = await CampaignService.get(request.campaignId);
};

const findScreenImpl = async (request: FindCampaignReq, result: FindCampaignRes, thunkAPI) => {
    const query = {
        name: request.dataForm.name,
        start_date: request.dataForm.start_date,
        end_date: request.dataForm.end_date,

    };

    result.campaignList = await CampaignService.find(query);
};

const viewReportImpl = async (request: FindCampaignReq, result: FindCampaignRes, thunkAPI) => {
    const { findCampaignMdl } = thunkAPI.getState();
    const campaignIdList = findCampaignMdl.selectedCampaignList.map(campaign => campaign.id);
    result.reportData = await CampaignService.viewReport(campaignIdList);
};

const FindCampaignCtrl = {
    getCampaign: action<FindCampaignReq, FindCampaignRes>(getCampaignImpl, 'FindCampaignCtrlCtrl/getCampaign'),
    findCampaign: action<FindCampaignReq, FindCampaignRes>(findScreenImpl, 'FindCampaignCtrlCtrl/findCampaign'),
    viewReport: action<FindCampaignReq, FindCampaignRes>(viewReportImpl, 'FindCampaignCtrlCtrl/viewReport'),
};

export default FindCampaignCtrl;
