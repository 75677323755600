import { UploadOutlined } from '@ant-design/icons';
import { Form, Space } from 'antd';
import { Block, ChampListe, ChampTexte, ConfirmDialog, Formulaire, useOnChange } from 'component';
import WarningFilledButton from 'component/button/WarningFilledButton';
import { useExecute, util } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ScreenStatusMapCtrl from '../ScreenStatusMapCtrl';
import { clusterListSelector, resultSelector, selectedScreenListSelector } from '../ScreenStatusMapMdl';

const SaveSelectionAction = () => {
    const result = useSelector(resultSelector);
    const clusterList = useSelector(clusterListSelector);
    const selectedScreenList = useSelector(selectedScreenListSelector);
    const { execute: executeListCluster, success: successListCluster } = useExecute(result);
    const { execute: executeSaveCluster, success: successSaveCluster } = useExecute(result);
    const [visible, setVisible] = useState(false);
    const [clusterChoosed, setClusterChoosed] = useState(false);
    const [form] = Form.useForm();

    const saveSelection = () => {
        executeListCluster(ScreenStatusMapCtrl.listCluster);
    };
    useEffect(() => {
        if (successListCluster) {
            setVisible(true);
        }
    }, [successListCluster]);

    useEffect(() => {
        if (successListCluster) {
            setVisible(false);
        }
    }, [successSaveCluster]);

    const close = () => {
        setVisible(false);
    };

    const confirm = () => {
        const dataForm = form.getFieldsValue();
        if (clusterChoosed) {
            executeSaveCluster(ScreenStatusMapCtrl.saveCluster, { clusterId: dataForm.cluster.id });
        } else if (dataForm.name) {
            executeSaveCluster(ScreenStatusMapCtrl.addCluster, { name: dataForm.name });
        }
    };

    useOnChange('cluster', form, (value) => {
        setClusterChoosed(util.nonNul(value?.id));
    });
    return (
        <Space>
            <WarningFilledButton name="saveSelection" icon={<UploadOutlined />} action={saveSelection} inactive={selectedScreenList?.length < 1 ? 'No Screen Selected' : null} />
            <ConfirmDialog //
                name="saveSelection"
                visible={visible}
                closeAction={close}
                confirmAction={confirm}
                width={1200}
            >
                <div style={{ marginBottom: '10px' }}> You have selected {selectedScreenList.length}, you can save this selection as a cluster</div>
                <Block width="400px">
                    <Formulaire form={form} nombreColonne={1}>
                        <ChampListe nom="cluster" liste={clusterList} libelle="Replace an existing cluster" />
                        <ChampTexte nom="name" libelle="Or create new One" disabled={clusterChoosed} />
                    </Formulaire>
                </Block>
            </ConfirmDialog>
        </Space>
    );
};
export default SaveSelectionAction;
