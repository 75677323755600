import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import useAuthContext from './AuthContext';
import { IUser } from './AuthDataModel';
import { FrameContent, LoginFrame } from './style';

export const AuthPage = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const { init } = useAuthContext();

    const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };
    const tailLayout = { wrapperCol: { span: 8, offset: 16 } };

    const login = async (values) => {
        try {
            const response: any = await axios.post(`${API_URL}/login`, values);
            const data = response.data;
            const parsedToken = JSON.parse(atob(data.token.split('.')[1]));
            const user: IUser = {
                username: parsedToken.user.email,
                role: 'ADMIN', //parsedToken.user.profile,
                token: data.token,
                did: parsedToken.user.did,
                expiryTime: parsedToken.expiry,
                creationTime: parsedToken.iat,
            };
            init(user);
            navigate('/');
        } catch (error) {
            setError('Nom d utilisateur ou mot de passe incorrect');
        }
    };

    return (
        <LoginFrame>
            <FrameContent>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <Form {...layout} name="basic" onFinish={login}>
                    <Form.Item label="Login" name="email" rules={[{ required: true, message: 'Nom d utilisateur obligatoire!' }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Login" />
                    </Form.Item>
                    <Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: 'Mode de passe obligatoire!' }]}>
                        <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Mot de passe" />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" block htmlType="submit">
                            Connexion
                        </Button>
                    </Form.Item>
                </Form>
            </FrameContent>
        </LoginFrame>
    );
};

export default AuthPage;
