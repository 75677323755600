import { HistoryOutlined } from '@ant-design/icons';
import { Checkbox, CheckboxProps, Col, Form, InputNumber, Row, Space } from 'antd';
import { Block, PrimaryFilledPanel } from 'component';
import { useAppDispatch } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { excludedOfflineCountSelector, ScreenStatusMapMdl, showOfflineSelector } from '../ScreenStatusMapMdl';

const OfflineFilterPanel = () => {
    const dispatch = useAppDispatch();
    const [delay, setDelay] = useState<string | number | null>(1);
    const showOffline = useSelector(showOfflineSelector);
    const excludeOfflineCount = useSelector(excludedOfflineCountSelector);

    const changeShowOffline: CheckboxProps['onChange'] = (e) => {
        dispatch(ScreenStatusMapMdl.setShowOffline(e.target.checked));
    };

    useEffect(() => {
        dispatch(ScreenStatusMapMdl.setDelay(+delay * 3600));
    }, [dispatch, delay]);

    return (
        <PrimaryFilledPanel title="offline.filter">
            <Block>
                <Space direction="vertical">
                    <Row>
                        <Col span={24}>
                            <Checkbox onChange={changeShowOffline} checked={showOffline}>
                                {showOffline || excludeOfflineCount === 0 ? 'Include  Offline ' : 'Include ' + excludeOfflineCount + ' Offlines '}
                            </Checkbox>
                        </Col>
                    </Row>
                </Space>
                <Space direction="vertical">
                    <Row>
                        <Col span={24}>
                            <Form>
                                <Form.Item label="Since">
                                    <InputNumber min={1} max={300} addonBefore={<HistoryOutlined />} suffix="Hour" value={delay} onChange={setDelay} disabled={!showOffline} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Space>
            </Block>
        </PrimaryFilledPanel>
    );
};

export default OfflineFilterPanel;
