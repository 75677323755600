import { Col, Form, Row, Select } from 'antd';
import { ConfirmDialog, useOnChange } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useExecute, util } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FindScreenCtrl from '../FindScreenCtrl';
import { metadataSelector, resultSelector, selectedScreenListSelector } from '../FindScreenMdl';

const { Option } = Select;

const ExportScreenDialog = ({ visible, setVisible }) => {
    const selectedScreenList = useSelector(selectedScreenListSelector);
    const metadata = useSelector(metadataSelector);
    const result = useSelector(resultSelector);
    const { execute, success } = useExecute(result);
    const [form] = Form.useForm();
    const [inactive, setInactive] = useState(null);

    const close = () => {
        setVisible(false);
    };

    const confirm = () => {
        const dataForm = util.removeNonSerialisable(form.getFieldsValue());
        execute(FindScreenCtrl.exportScreen, { dataForm });
    };
    useEffect(() => {
        if (success) {
            setVisible(false);
        }
    }, [success]);

    useOnChange('exportKeys', form, (value) => {
        setInactive(value?.length > 0 ? null : 'No Selected Fields');
    });

    return (
        <ConfirmDialog name="exportScreen" visible={visible} closeAction={close} confirmAction={confirm} width={1200} inactive={inactive}>
            <Row gutter={20}>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Selected Screen List">
                        <Table data={selectedScreenList}>
                            <Column name="id" />
                            <Column name="name" />
                            <Column name="zipcode" />
                        </Table>
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Select Fields To Be Exported">
                        <Form layout="vertical" form={form}>
                            <Form.Item name="exportKeys" label="Selected Field">
                                <Select mode="multiple">
                                    {metadata?.exportOptionList?.screen?.map((o) => (
                                        <Option key={o.key} value={o.key}>
                                            {o.label}
                                        </Option>
                                    ))}
                                    {metadata?.exportOptionList?.player?.map((o) => (
                                        <Option key={o.key} value={o.key}>
                                            {o.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Form>
                    </PrimaryOutlinedPanel>
                </Col>
            </Row>
        </ConfirmDialog>
    );
};

export default ExportScreenDialog;
