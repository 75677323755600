import { STable } from 'component/table/styles';
import { createSorter } from 'component/table/tableUtils';
import { useExecute } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ListContentCtrl from '../ListContentCtrl';
import { contentListSelector } from '../ListContentMdl';

const ContentTable = () => {
    const contentList = useSelector(contentListSelector);
    const { execute } = useExecute();
    const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    useEffect(() => {
        execute(ListContentCtrl.listContent);
    }, []);

    const columns = [
        {
            title: 'BSC ID',
            dataIndex: 'bscid',
            key: 'bscid',
            sorter: createSorter('bscid'),
        },
        {
            title: 'Container ID',
            dataIndex: 'container_id',
            key: 'container_id',
            sorter: createSorter('container_id'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: createSorter('name'),
        },
        {
            title: 'MIME',
            dataIndex: 'mime',
            key: 'mime',
            sorter: createSorter('mime'),
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            sorter: createSorter('size', true),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: createSorter('status'),
        },
        {
            title: 'Creation Time',
            dataIndex: 'creation_tm',
            key: 'creation_tm',
            sorter: createSorter('creation_tm'),
        },
    ];

    return (
        <STable
            dataSource={contentList}
            rowKey="bscid"
            columns={columns}
            pagination={{
                ...pagination,
                total: contentList?.length,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
            bordered
        />
    );
};

export default ContentTable;
