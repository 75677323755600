import { App, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
// eslint-disable-next-line no-unused-vars
import en from 'antd/locale/en_US';
import fr from 'antd/locale/fr_FR';
import 'dayjs/locale/en';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import StyledThemeProvider from './StyledThemeProvider';

const antdLocaleMap = { fr: fr, en: en };
const AntdThemeProvider = ({ children, theme, locale }) => {
    dayjs.locale(locale);
    dayjs.extend(duration);
    dayjs.extend(relativeTime);
    dayjs.extend(customParseFormat);
    dayjs.extend(advancedFormat);
    dayjs.extend(weekday);
    dayjs.extend(localeData);
    dayjs.extend(weekOfYear);
    dayjs.extend(weekYear);
    return (
        <App>
            <ConfigProvider theme={theme} locale={antdLocaleMap[locale]}>
                <StyledThemeProvider>{children}</StyledThemeProvider>
            </ConfigProvider>
        </App>
    );
};

export default AntdThemeProvider;
