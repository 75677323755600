import { DownloadOutlined } from '@ant-design/icons';
import { Form, Space } from 'antd';
import { Block, ChampListe, ConfirmDialog, Formulaire } from 'component';
import WarningFilledButton from 'component/button/WarningFilledButton';
import { useExecute } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ScreenStatusMapCtrl from '../ScreenStatusMapCtrl';
import { clusterListSelector, locationListSelector, resultSelector } from '../ScreenStatusMapMdl';

const LoadSelectionAction = () => {
    const result = useSelector(resultSelector);
    const clusterList = useSelector(clusterListSelector);
    const locationList = useSelector(locationListSelector);
    const { execute: executeListCluster, success: successListCluster } = useExecute(result);
    const { execute: executeSetCluster, success: successSetCluster } = useExecute(result);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const loadSelection = () => {
        executeListCluster(ScreenStatusMapCtrl.listCluster);
    };
    useEffect(() => {
        if (successListCluster) {
            setVisible(true);
            form.resetFields();
        }
    }, [successListCluster]);

    useEffect(() => {
        if (successSetCluster) {
            close();
        }
    }, [successSetCluster]);

    const close = () => {
        setVisible(false);
    };

    const confirm = () => {
        executeSetCluster(ScreenStatusMapCtrl.getCluster, { clusterId: form.getFieldValue('cluster').id });
    };

    return (
        <Space>
            <WarningFilledButton label="Load" icon={<DownloadOutlined />} action={loadSelection} />
            <ConfirmDialog //
                name="loadSelection"
                visible={visible}
                closeAction={close}
                confirmAction={confirm}
                width={1200}
            >
                <div style={{ marginBottom: '10px' }}>{locationList.length > 0 ? 'Select a cluster of screen to be added to the current selection' : 'Select a cluster of screen to be selected'} </div>
                <Block width="400px">
                    <Formulaire form={form} nombreColonne={1}>
                        <ChampListe nom="cluster" liste={clusterList} libelle="Cluster of Screen" />
                    </Formulaire>
                </Block>
            </ConfirmDialog>
        </Space>
    );
};
export default LoadSelectionAction;
