import { createSelector, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import _ from 'lodash';
import { ICluster } from 'model/cluster/ClusterDataModel';
import { IScreen } from 'model/screen/ScreenDataModel';
import ScreenStatusMapCtrl from './ScreenStatusMapCtrl';

const getDistanceInKm = (lat1, lng1, lat2, lng2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLng = (lng2 - lng1) * Math.PI / 180;
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};
const overrideSelected = (screens, currentClusterScreens) => {
    return screens.map((screen) => {
        const isInCluster = currentClusterScreens.some((clusterScreen) => clusterScreen.id === screen.id);
        const suffix = isInCluster || screen.selected ? '_SEL' : '_UNSEL';
        const tag = screen.status === 'online' ? 'ON' + suffix : 'OFF' + suffix;
        return {
            ...screen,
            tag,
            selected: isInCluster || screen.selected,
        };
    });
};

const calculateTaggedScreens = (locationList, currentCluster, filteredByStatus, radius) => {

    if (locationList.length > 0) {
        const taggedScreens = filteredByStatus.map((screen) => {
            const selected = locationList.some((location) => {
                const distance = getDistanceInKm(screen.lat, screen.lng, location.lat, location.lng);
                return distance * 1000 <= radius;
            });

            const suffix = selected ? '_SEL' : '_UNSEL';
            const tag = screen.status === 'online' ? 'ON' + suffix : 'OFF' + suffix;
            return { ...screen, tag, selected };
        });

        if (currentCluster?.screens?.length > 0) {
            const updatedScreens = overrideSelected(taggedScreens, currentCluster.screens);
            return _.unionBy(updatedScreens, currentCluster.screens, 'id');
        }

        return taggedScreens;
    } else {
        const taggedScreens = filteredByStatus.map((screen) => {
            const tag = screen.status === 'online' ? 'ON_UNSEL' : 'OFF_UNSEL';
            return { ...screen, tag, selected: false };
        });

        if (currentCluster?.screens?.length > 0) {
            return overrideSelected(filteredByStatus, currentCluster.screens);
        }


        return taggedScreens;
    }
};

const filterScreenList = (state) => {
    const { allScreenList, currentCluster, locationList, radius, showOffline, delay } = state;
    const dateInSecond = Date.now() / 1000;
    const statusDate = dateInSecond - delay;


    const filteredByStatus = allScreenList.filter(screen => {
        if (screen.status === 'online') {
            return true;
        } else if (!showOffline) {
            return false;
        }

        const lastUpdateInSecond = new Date(screen.status_last).getTime() / 1000;
        return statusDate > lastUpdateInSecond;
    });
    const list1 = calculateTaggedScreens(locationList, currentCluster, filteredByStatus, radius);

    const offlineScreens = allScreenList.filter(screen => screen.status !== 'online');

    const liste2 = calculateTaggedScreens(locationList, currentCluster, offlineScreens, radius);

    return {
        screens: list1,
        excludedOfflineCount: showOffline ? 0 : liste2.filter((screen) => screen.selected).length,
    };



};


export interface ScreenStatusMapReq extends IRequest {
    name?: string;
    clusterId?: string;

}

export interface ScreenStatusMapRes extends IResult {
    screenList?: IScreen[];
    clusterList?: ICluster[];
    cluster?: ICluster;
}

const initialState = {
    result: {} as ScreenStatusMapRes,
    screenList: [] as IScreen[],
    allScreenList: [] as IScreen[],
    clusterList: [] as ICluster[],
    currentScreen: {} as IScreen,
    currentCluster: {} as ICluster,
    oldCluster: {} as ICluster,
    delay: 0,
    showOffline: false,
    radius: 500,
    locationList: [] as any[],
    excludedOfflineCount: 0,

};
type ScreenStatusMapType = typeof initialState;

const SliceScreenStatusMap = createSlice({
    name: 'ScreenStatusMapMdl',
    initialState,
    reducers: {
        addScreen(state, action) {
            state.currentScreen = action.payload;
            const liste = state.currentCluster.screens ? [...state.currentCluster.screens] : [];
            const screenExists = liste.some(screen => screen.id === action.payload.id);

            if (!screenExists) {
                liste.push(action.payload);
                state.currentCluster.screens = liste;
                state.currentCluster.changed = true;
            }
        },
        removeScreen(state, action) {
            state.currentCluster.screens = state.currentCluster.screens.filter(screen => screen.id !== action.payload.id);
            state.currentCluster.changed = true;
        },

        selectScreen(state, action) {
            const screenIndex = state.screenList.findIndex(screen => screen.id === action.payload.id);

            if (screenIndex !== -1) {
                const selectedScreen = {
                    ...state.screenList[screenIndex],
                    tag: state.screenList[screenIndex].status === 'online' ? 'ON_SEL' : 'OFF_SEL',
                    selected: true
                };

                state.screenList[screenIndex] = selectedScreen;
            }
        },
        unselectScreen(state, action) {
            const screenIndex = state.screenList.findIndex(screen => screen.id === action.payload.id);

            if (screenIndex !== -1) {
                const selectedScreen = {
                    ...state.screenList[screenIndex],
                    tag: state.screenList[screenIndex].status === 'online' ? 'ON_UNSEL' : 'OFF_UNSEL',
                    selected: false
                };

                state.screenList[screenIndex] = selectedScreen;
            }
        },
        setDelay(state, action) {
            state.delay = action.payload;
            const r = filterScreenList(state);
            state.screenList = r.screens;
            state.excludedOfflineCount = r.excludedOfflineCount;
        },

        setShowOffline(state, action) {
            state.showOffline = action.payload;
            const r = filterScreenList(state);
            state.screenList = r.screens;
            state.excludedOfflineCount = r.excludedOfflineCount;
        },

        setRadius(state, action) {
            state.radius = action.payload === 0 ? 500 : action.payload * 1000;

            const r = filterScreenList(state);
            state.screenList = r.screens;
            state.excludedOfflineCount = r.excludedOfflineCount;
        },
        setLocationList(state, action) {
            state.locationList = action.payload;
            const r = filterScreenList(state);
            state.screenList = r.screens;
            state.excludedOfflineCount = r.excludedOfflineCount;
        },
        initCluster(state) {
            state.currentCluster = {} as ICluster;
            const r = filterScreenList(state);
            state.screenList = r.screens;
            state.excludedOfflineCount = r.excludedOfflineCount;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.listScreenForMap), (state, action) => {
                state.result = action.payload;
                state.allScreenList = action.payload.screenList;
                const r = filterScreenList(state);
                state.screenList = r.screens;
                state.excludedOfflineCount = r.excludedOfflineCount;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.listCluster), (state, action) => {
                state.result = action.payload;
                state.clusterList = action.payload.clusterList;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.getCluster), (state, action) => {
                state.result = action.payload;
                state.currentCluster = action.payload.cluster;
                const r = filterScreenList(state);
                state.screenList = r.screens;
                state.excludedOfflineCount = r.excludedOfflineCount;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.addCluster), (state, action) => {
                state.result = action.payload;
                state.currentCluster = action.payload.cluster;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.saveCluster), (state, action) => {
                state.result = action.payload;
            })
            .addMatcher(isPending(ScreenStatusMapCtrl.addCluster, ScreenStatusMapCtrl.saveCluster, ScreenStatusMapCtrl.getCluster, ScreenStatusMapCtrl.listScreenForMap, ScreenStatusMapCtrl.listCluster), (state) => {
                state.result = {} as ScreenStatusMapRes;
            })
            .addMatcher(isRejected(ScreenStatusMapCtrl.addCluster, ScreenStatusMapCtrl.saveCluster, ScreenStatusMapCtrl.getCluster, ScreenStatusMapCtrl.listScreenForMap, ScreenStatusMapCtrl.listCluster), (state) => {
                state.result = { rid: 'erreur' } as ScreenStatusMapRes;
            });
    },
});

export const ScreenStatusMapMdl = SliceScreenStatusMap.actions;

const screenStatusMapSelector = (state) => state.screenStatusMapMdl;
export const resultSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.result);
export const screenListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.screenList);
export const selectedScreenListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => { return state.screenList.filter((s) => s.selected); });
export const allScreenListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.allScreenList);
export const clusterListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.clusterList);
export const currentScreenSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.currentScreen);
export const currentClusterSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.currentCluster);
export const delaySelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.delay);
export const showOfflineSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.showOffline);
export const radiusSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.radius);
export const locationListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.locationList);
export const excludedOfflineCountSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.excludedOfflineCount);



export default SliceScreenStatusMap.reducer;