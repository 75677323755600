import { ClearOutlined, DeleteFilled, DownOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Form, Input, Row, Select, Space, TreeSelect } from 'antd';
import { MenuProps } from 'antd/lib';
import IconButton from 'component/button/IconButton';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import Panel from 'component/container/Panel';
import { useAppDispatch, useExecute, util } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FindScreenCtrl from '../FindScreenCtrl';
import { clusterListSelector, FindScreenMdl, metadataSelector } from '../FindScreenMdl';

const { Option } = Select;

const ScreenFilter = () => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const [form] = Form.useForm();
    const [edit, setEdit] = useState(true);
    const metadata = useSelector(metadataSelector);
    const clusterList = useSelector(clusterListSelector);
    const [value, setValue] = useState<string>();
    const [treeData, setTreeData] = useState([]);
    const [keyList, setKeyList] = useState<MenuProps['items']>([]);
    const [formItems, setFormItems] = useState<{ key: string }[]>([]);
    const statusList = [
        { id: 'online', name: 'Online' },
        { id: 'offline', name: 'Offline' },
        { id: 'MIA', name: 'MIA' },
    ];

    const onClick: MenuProps['onClick'] = ({ key }) => {
        if (!formItems.some((item) => item.key === key)) {
            setFormItems([...formItems, { key }]);
        }
    };

    useEffect(() => {
        dispatch(FindScreenMdl.init());
    }, []);

    const convertTree = (node: any) => {
        if (node.children?.length > 0) {
            return {
                title: node.name,
                value: '' + node.id,
                children: node.children?.map(convertTree),
            };
        } else {
            return {
                title: node.name,
                value: '' + node.id,
                isLeaf: true,
            };
        }
    };

    useEffect(() => {
        if (metadata?.criteriaTree?.children?.length > 0) {
            const newTree = convertTree(metadata.criteriaTree);
            const a = [];
            a.push(newTree);
            setTreeData(a);
        }
        if (metadata?.playerFiltersList) {
            const kl = metadata?.playerFiltersList.map((item) => {
                return { key: item.key, label: item.key };
            });
            setKeyList(kl);
        }
    }, [metadata]);

    const collectLeafNodes = (treeData, selectedKeys) => {
        const leafNodes = [];

        const findLeaves = (node) => {
            if (node.children && node.children.length > 0) {
                node.children.forEach(findLeaves);
            } else {
                leafNodes.push(node.value);
            }
        };

        const traverseTree = (nodes) => {
            nodes.forEach((node) => {
                if (selectedKeys.includes(node.value)) {
                    findLeaves(node);
                }
                if (node.children && node.children.length > 0) {
                    traverseTree(node.children);
                }
            });
        };

        traverseTree(treeData);

        return leafNodes;
    };

    const find = () => {
        const formValues = util.removeNonSerialisable(form.getFieldsValue());
        const selectedCriteria = formValues.criteriaIds || [];
        const leafNodes = collectLeafNodes(treeData, selectedCriteria);
        formValues.criteriaIds = leafNodes;
        execute(FindScreenCtrl.findScreen, {
            dataForm: formValues,
            currentPage: 1,
        });

        setEdit(false);
    };

    const editQuery = () => {
        setEdit(true);
    };

    const clearQuery = () => {
        form.resetFields();
        find();
        setEdit(true);
    };

    const onChange = (newValue: string) => {
        console.log(newValue);
        setValue(newValue);
    };

    const removeFormItem = (keyToRemove: string) => {
        setFormItems(formItems.filter((item) => item.key !== keyToRemove));
        form.resetFields([keyToRemove]);
    };

    return (
        <div>
            {edit && metadata?.playerFiltersList && (
                <Form layout="vertical" form={form}>
                    <Row gutter={20}>
                        <Col span="12">
                            <Form.Item name="name" label="Name">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Form.Item name="zipcode" label="Zip Code">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Form.Item name="status" label="Status">
                                <Select>
                                    {statusList?.map((o) => (
                                        <Option key={o.id} value={o.id}>
                                            {o.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Form.Item name="clusterIds" label="Clusters">
                                <Select mode="multiple">
                                    {clusterList?.map((o) => (
                                        <Option key={o.id} value={o.id}>
                                            {o.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Panel label="Dynamic Filter" type="simple">
                                {formItems.map((item) => (
                                    <Row key={item.key}>
                                        <Col flex="auto">
                                            <Form.Item name={item.key} label={item.key}>
                                                <Select mode="multiple">
                                                    {metadata?.playerFiltersList
                                                        .find((dataItem) => dataItem.key === item.key)
                                                        ?.values.map((value) => (
                                                            <Option key={value} value={value}>
                                                                {value}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col flex="none" style={{ display: 'flex', alignItems: 'end' }}>
                                            <IconButton icon={<DeleteFilled />} action={() => removeFormItem(item.key)} />
                                        </Col>
                                    </Row>
                                ))}
                                <div style={{ margin: '20px 0' }}>
                                    <Dropdown menu={{ items: keyList, onClick }}>
                                        <Button onClick={(e) => e.preventDefault()}>
                                            <Space>
                                                Add Filter
                                                <DownOutlined />
                                            </Space>
                                        </Button>
                                    </Dropdown>
                                </div>
                            </Panel>
                        </Col>
                        <Col span="12">
                            <Panel label="Criteria Filter" type="simple">
                                <Form.Item name="criteriaIds" label="Criteria List">
                                    <TreeSelect //
                                        showSearch
                                        style={{ width: '100%' }}
                                        value={value}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="Please select"
                                        allowClear
                                        multiple
                                        filterTreeNode
                                        treeNodeFilterProp="title"
                                        treeDefaultExpandAll
                                        treeCheckable={true}
                                        showCheckedStrategy="SHOW_PARENT"
                                        onChange={onChange}
                                        treeData={treeData}
                                    />
                                </Form.Item>
                            </Panel>
                        </Col>
                    </Row>
                </Form>
            )}
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <PrimaryOutlinedButton icon={<ClearOutlined />} name="clearQuery" action={clearQuery} />
                    {edit && <PrimaryFilledButton icon={<SearchOutlined />} name="find" action={find} />}
                    {!edit && <PrimaryFilledButton icon={<EditOutlined />} name="editQuery" action={editQuery} />}
                </Space>
            </div>
        </div>
    );
};

export default ScreenFilter;
