import { RoleAreaType } from 'core';
import AdminModuleList from './admin/modules';

const roleAreaMap: Record<string, RoleAreaType> = {
    ADMIN: {
        moduleList: AdminModuleList,
    },
    USERDEFAULT: {
        moduleList: AdminModuleList,
    },
};

export default roleAreaMap;