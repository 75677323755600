import { IScreen } from './ScreenDataModel';

const applyMarkerOffset = (screens: IScreen[]): IScreen[] => {
    const markerMap: { [key: string]: IScreen[] } = {};

    screens.forEach((screen) => {
        const key = `${screen.lat},${screen.lng}`;
        if (!markerMap[key]) {
            markerMap[key] = [];
        }
        markerMap[key].push(screen);
    });

    const offsetScreens: IScreen[] = [];

    Object.values(markerMap).forEach((screenGroup) => {
        if (screenGroup.length > 1) {
            screenGroup.forEach((screen, index) => {
                const offset = (index + 1) * 0.00001;
                offsetScreens.push({
                    ...screen,
                    lat: screen.lat + offset,
                    lng: screen.lng + offset,
                });
            });
        } else {
            offsetScreens.push(screenGroup[0]);
        }
    });

    return offsetScreens;
};

const ScreenMapper = {
    applyMarkerOffset,
};
export default ScreenMapper;