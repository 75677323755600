import { APIProvider } from '@vis.gl/react-google-maps';
import axios from 'axios';
import { API_URL, GOOGLE_MAPS_API_KEY } from 'common/config/constants.config';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ListScreenPanel from './element/ListScreenPanel';
import SharedClusterGoogleMaps from './element/SharedClusterGoogleMaps';

const ShareClusterMapView = () => {
    const [cluster, setCluster] = useState({ screenList: [] });
    const { clusterId } = useParams();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${API_URL}/open/clusters/${clusterId}?did=86478582`);
                setCluster(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
                <SharedClusterGoogleMaps screenList={cluster.screenList} />
            </APIProvider>

            <ListScreenPanel cluster={cluster} />
        </div>
    );
};

export default ShareClusterMapView;
