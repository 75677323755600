import { Col, Row } from 'antd';
import { useI18n } from 'core';
import styled from 'styled-components';

const RootComponent = styled.div`
    background-color: #fff;
    &.transparent.primary {
        background-color: transparent;
    }
`;

const Header = styled(Row)`
    align-items: center;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    &.noBorder.primary {
        color: ${(props) => props.theme.token.colorPrimary};
        background-color: #fff;
    }
    &.noBorder.secondary {
        color: ${(props) => props.theme.token.colorWarning};
        background-color: #fff;
    }
    &.filled.primary {
        color: #fff;
        background-color: ${(props) => props.theme.token.colorPrimary};
        border-bottom: none;
        padding: 5px 5px 0 5px;
    }
    &.filled.secondary {
        color: #fff;
        background-color: ${(props) => props.theme.token.colorWarning};
        border-bottom: none;
        padding: 5px 5px 0 5px;
    }
    &.outlined.primary {
        color: ${(props) => props.theme.token.colorPrimary};
        background-color: ${(props) => `${props.theme.token.colorPrimary}0D`};
        border: 1px solid ${(props) => `${props.theme.token.colorPrimary}4D`};
        border-bottom: none;
        padding: 5px 5px 0 5px;
    }
    &.outlined.secondary {
        color: ${(props) => props.theme.token.colorWarning};
        background-color: ${(props) => `${props.theme.token.colorWarning}0D`};
        border: 1px solid ${(props) => `${props.theme.token.colorWarning}4D`};
        border-bottom: none;
        padding: 5px 5px 0 5px;
    }
    &.simple.primary {
        color: ${(props) => props.theme.token.colorPrimary};
        background-color: #fff;
        border: 1px solid ${(props) => `${props.theme.token.colorPrimary}4D`};
        border-bottom: none;
        padding: 5px 5px 0 5px;
    }
    &.simple.secondary {
        color: ${(props) => props.theme.token.colorWarning};
        background-color: #fffef6;
        border: none;
        padding: 5px 5px 0 5px;
    }
    &.transparent.primary {
        color: ${(props) => props.theme.token.colorPrimary};
        background-color: transparent;
        border: none;
        padding: 5px 5px 0 5px;
    }
`;

const Titre = styled(Col)`
    flex: none;
    padding-bottom: 3px;
    font-weight: 700;
`;

const Action = styled(Col)`
    flex: auto;
    text-align: right;
    height: 28px;
    .ant-btn {
        padding: 2px 10px 0 10px;
        height: 22px;
        font-size: 12px;
    }
`;

const Body = styled(Row)`
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    &.noBorder.primary {
    }
    &.noBorder.secondary {
    }
    &.filled.primary {
        border: 1px solid ${(props) => `${props.theme.token.colorPrimary}4D`};
    }
    &.filled.secondary {
        border: 1px solid ${(props) => `${props.theme.token.colorWarning}4D`};
    }
    &.outlined.primary {
        background-color: #fff;
        border: 1px solid ${(props) => `${props.theme.token.colorPrimary}4D`};
    }
    &.outlined.secondary {
        background-color: #fff;
        border: 1px solid ${(props) => `${props.theme.token.colorWarning}4D`};
    }
    &.simple.primary {
        background-color: #fff;
        border: 1px solid ${(props) => `${props.theme.token.colorPrimary}4D`};
    }
    &.simple.secondary {
        background-color: #fff;
        border: none;
    }
    &.transparent.primary {
        background-color: transparent;
        border: none;
    }
`;

const Panel = ({ title = null, label = null, marge = '10px', type = 'noBorder', color = 'primary', actionbloc = null, children }) => {
    const { i18n } = useI18n();
    return (
        <RootComponent className={type + ' ' + color}>
            {(title || label) && (
                <Header className={type + ' ' + color}>
                    <Titre> {label || i18n(title)}</Titre>
                    <Action> {actionbloc}</Action>
                </Header>
            )}
            <Body className={type + ' ' + color} style={{ padding: marge }}>
                <Col span={24}>{children}</Col>
            </Body>
        </RootComponent>
    );
};

export default Panel;
