import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { ICampaign } from './CampaignDataModel';

const get = async (campaignId): Promise<ICampaign> => {
    const response = await axios.get(`${API_URL}/campaigns/${campaignId}`);
    return response.data;
};

const find = async (campaignQuery) => {
    const response = await axios.post(`${API_URL}/campaigns`, campaignQuery);
    return response.data?.rows;
};

const viewReport = async (campaignIds: string[]) => {
    const response = await axios.post(`${API_URL}/campaigns/report-sum`, { campaignIds });
    return response.data?.day_perf;
};


const CampaignService = {
    get,
    find,
    viewReport,
};

export default CampaignService;