import { Pie } from '@ant-design/plots';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { contentListSelector } from '../ListContentMdl';

const ContentGraph = () => {
    const contentList = useSelector(contentListSelector);
    const defaultConf = {
        angleField: 'count',
        colorField: 'status',
        innerRadius: 0.6,
        label: {
            text: 'count',
            style: {
                fontWeight: 'bold',
                color: 'white',
            },
        },
        legend: {
            color: {
                title: false,
                position: 'right',
                rowPadding: 5,
            },
        },
    };
    const getPie = useCallback(() => {
        if (contentList?.length < 1) return null;
        const reducedList = contentList.reduce((acc, item) => {
            if (!acc[item.status]) {
                acc[item.status] = 0;
            }
            acc[item.status] += 1;
            return acc;
        }, {} as Record<string, number>);
        const data = Object.keys(reducedList).map((status) => ({
            status,
            count: reducedList[status],
        }));
        const config = { ...defaultConf, data };
        return <Pie {...config} />;
    }, [contentList]);

    return <div>{getPie()}</div>;
};

export default ContentGraph;
