import { ChampTexte, Column, Formulaire, Tab, TabMenu, Table } from 'component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { metadataSelector } from '../FindScreenMdl';

const ScreenDetailPanel = ({ form, screen }) => {
    const metadata = useSelector(metadataSelector);
    const [loopPolicyList, setLoopPolicyList] = useState({});

    useEffect(() => {
        if (metadata?.loopPolicyList) {
            const loopPolicyList = metadata.loopPolicyList.reduce((acc, loopPolicy) => {
                acc[loopPolicy.id] = loopPolicy.name;
                return acc;
            }, {});
            setLoopPolicyList(loopPolicyList);
        }
    }, []);

    const reverseMinuteMask = (minute_mask) => {
        const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const dayIntervals = minute_mask.split(';');
        const resultTable = [];

        dayIntervals.forEach((interval, index) => {
            const [startMinute, endMinute] = interval.split('-').map(Number);

            const startHour = Math.floor((startMinute % (24 * 60)) / 60);
            const startMin = startMinute % 60;

            const endHour = Math.floor((endMinute % (24 * 60)) / 60);
            const endMin = endMinute % 60;

            const startFormatted = `${String(startHour).padStart(2, '0')}:${String(startMin).padStart(2, '0')}`;
            const endFormatted = `${String(endHour).padStart(2, '0')}:${String(endMin).padStart(2, '0')}`;

            resultTable.push({
                day: dayNames[index],
                start: startFormatted,
                end: endFormatted,
            });
        });

        return resultTable;
    };

    const expandedRowRender = (dayPart) => {
        return (
            <Table data={reverseMinuteMask(dayPart.minute_mask)}>
                <Column name="day" />
                <Column name="start" />
                <Column name="end" />
            </Table>
        );
    };

    const rowExpandable = () => {
        return true;
    };

    //
    return (
        <TabMenu>
            <Tab key="info">
                <Formulaire form={form} nombreColonne={2}>
                    <ChampTexte nom="id" disabled />
                    <ChampTexte nom="updatedAt" disabled />
                    <ChampTexte nom="name" disabled />
                    <ChampTexte nom="zipcode" disabled />
                    <ChampTexte nom="status" disabled />
                    <ChampTexte nom="status_last" disabled />
                    <ChampTexte nom="lat" disabled />
                    <ChampTexte nom="lng" disabled />
                </Formulaire>
            </Tab>
            <Tab key="frame">
                <Table data={screen?.metadata?.frameList}>
                    <Column name="name" />
                    <Column name="geometry.text" />
                    <Column name="loop_policy_id" content={(r) => loopPolicyList[r]} />
                </Table>
            </Tab>
            <Tab key="dayPart">
                <Table data={screen?.metadata?.daypartList} expandable={{ expandedRowRender, rowExpandable, defaultExpandedRowKeys: ['0'] }}>
                    <Column name="active" boolean />
                    <Column name="name" />
                    <Column name="start_date" />
                    <Column name="end_date" />
                    <Column name="weight" />
                </Table>
            </Tab>
        </TabMenu>
    );
};
export default ScreenDetailPanel;
