import { BarChartOutlined, DeleteOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import { PrimaryFilledPanel, RowSpacing } from 'component';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useAppDispatch } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FindCampaignMdl, selectedCampaignListSeclector } from '../FindCampaignMdl';
import ViewReportDialog from './ViewReportDialog';

const SelectedCampaignTable = ({ viewCampaign }) => {
    const dispatch = useAppDispatch();
    const selectedCampaignList = useSelector(selectedCampaignListSeclector);
    const [viewReportVisible, setViewReportVisible] = useState(false);

    const removeCampaign = (campaign) => {
        dispatch(FindCampaignMdl.removeCampaign(campaign));
    };

    const getDateColumn = (index, campaign) => {
        return (
            <div>
                <div>{campaign.start_date}</div>
                <div>{campaign.end_date}</div>
            </div>
        );
    };
    const viewReport = () => {
        setViewReportVisible(true);
    };
    return (
        <Badge.Ribbon text={selectedCampaignList?.length} color="red">
            <PrimaryFilledPanel title="campaign.selectedList">
                <Table data={selectedCampaignList}>
                    <Column name="id" />
                    <Column name="name" />
                    <Column name="removeCampaign" action={removeCampaign} icon={<DeleteOutlined />} />
                </Table>
                {selectedCampaignList?.length > 0 && (
                    <div style={{ textAlign: 'right' }}>
                        <RowSpacing />
                        <Space>
                            <PrimaryOutlinedButton name="viewReport" icon={<BarChartOutlined />} action={viewReport} />
                        </Space>
                    </div>
                )}
                <ViewReportDialog visible={viewReportVisible} setVisible={setViewReportVisible} />
            </PrimaryFilledPanel>
        </Badge.Ribbon>
    );
};

export default SelectedCampaignTable;
