import { ClearOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Space } from 'antd';
import { ChampDate, ChampTexte, Formulaire } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import { useAppDispatch, useExecute, util } from 'core';
import { useEffect, useState } from 'react';
import FindCampaignCtrl from '../FindCampaignCtrl';
import { FindCampaignMdl } from '../FindCampaignMdl';

const CampaignFilter = () => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const [form] = Form.useForm();
    const [edit, setEdit] = useState(true);

    useEffect(() => {
        dispatch(FindCampaignMdl.init());
    }, []);

    const find = () => {
        const formValues = util.removeNonSerialisable(form.getFieldsValue());
        execute(FindCampaignCtrl.findCampaign, {
            dataForm: formValues,
            currentPage: 1,
        });

        setEdit(false);
    };
    const editQuery = () => {
        setEdit(true);
    };

    const clearQuery = () => {
        form.resetFields();
        find();
        setEdit(true);
    };

    return (
        <div>
            {edit && (
                <Formulaire form={form} nombreColonne={2}>
                    <ChampTexte nom="name" aloneInRow />
                    <ChampDate nom="start_date" />
                    <ChampDate nom="end_date" />
                </Formulaire>
            )}
            <div style={{ textAlign: 'right' }}>
                <Space>
                    <PrimaryOutlinedButton icon={<ClearOutlined />} name="clearQuery" action={clearQuery} />
                    {edit && <PrimaryFilledButton icon={<SearchOutlined />} name="find" action={find} />}
                    {!edit && <PrimaryFilledButton icon={<EditOutlined />} name="editQuery" action={editQuery} />}
                </Space>
            </div>
        </div>
    );
};

export default CampaignFilter;
