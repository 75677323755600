import { PlusCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, List, Row, Slider, SliderSingleProps, Space, Upload, UploadProps } from 'antd';
import { GOOGLE_MAPS_API_KEY } from 'common/config/constants.config';
import { Block, PrimaryFilledPanel, RowSpacing } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import { useAppDispatch } from 'core';
import Papa from 'papaparse';
import { useState } from 'react';
import { ScreenStatusMapMdl } from '../ScreenStatusMapMdl';

const PositionFiltrePanel = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const [addressList, setAddressList] = useState([]);
    const [inputValue, setInputValue] = useState();

    const marks: SliderSingleProps['marks'] = {
        0: '500m',
        25: '1Km',
        50: '10Km',
        100: '50Km',
    };

    const handleUpload = (file): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: false,
                complete: (results) => {
                    resolve(results.data);
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const props: UploadProps = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        fileList: [],
        beforeUpload: (file) => {
            form.setFieldsValue({ file });
            return false;
        },
        onRemove: () => {
            form.setFieldsValue({ file: null });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
                setAddressListByFile();
            }
            if (info.file.status === 'error') {
                console.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const setAddressListByFile = async () => {
        try {
            const addresses: any[] = await handleUpload(form.getFieldValue('file'));
            setAddressList(addresses);
        } catch (error) {
            console.error('Error processing uploaded file:', error);
        }
    };

    const filter = async () => {
        const geocodedLocations = await Promise.all(
            addressList.map(async (address) => {
                try {
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`);
                    const data = await response.json();
                    if (data.status === 'OK') {
                        return data.results[0].geometry.location;
                    } else {
                        console.log('Failed to geocode:', address, data.status);
                        return null;
                    }
                } catch (err) {
                    console.error('Geocoding error:', err);
                    return null;
                }
            })
        );
        const validLocations = geocodedLocations.filter((location) => location !== null);
        form.resetFields();
        dispatch(ScreenStatusMapMdl.setLocationList(validLocations));
    };

    const radiusChanged = (value) => {
        dispatch(ScreenStatusMapMdl.setRadius(value));
    };

    const addAddress = () => {
        if (inputValue) {
            setAddressList([...addressList, inputValue]);
            setInputValue(null);
        }
    };
    const reset = () => {
        form.setFieldsValue({ file: null });
        form.resetFields();
        setAddressList([]);
        dispatch(ScreenStatusMapMdl.setLocationList([]));
    };
    return (
        <PrimaryFilledPanel label="Select By Location   ">
            <Block>
                <Row>
                    <Col span={24}>
                        <List //
                            header="Select Screens Nearby Following Addresses"
                            bordered
                            dataSource={addressList}
                            renderItem={(item, index) => <List.Item style={{ color: '#7209B7' }}>{++index + '. ' + item}</List.Item>}
                        />
                    </Col>
                </Row>
                <RowSpacing />
                <Row>
                    <Col span="24">
                        <Space>
                            <Input value={inputValue} onChange={handleInputChange} placeholder="Type address" />
                            <PrimaryFilledButton action={addAddress} icon={<PlusCircleOutlined />} shape="filled" size="middle" color="primary" />
                        </Space>
                    </Col>
                </Row>
                <RowSpacing />
                <Row>
                    <Col span={24}>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>Upload Adress List</Button>
                        </Upload>
                    </Col>
                </Row>
                <RowSpacing />
                <Row>
                    <Col span={24}>
                        <Form form={form} layout="vertical">
                            <Form.Item name="radius" label="Selection radius">
                                <Slider marks={marks} step={null} defaultValue={0} tooltip={{ open: false }} style={{ margin: '10px 16  px' }} onChange={radiusChanged} />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
                <RowSpacing />
                <Row>
                    <Col span={24}>
                        <Space>
                            <PrimaryFilledButton name="addToSelection" action={filter} />
                            <PrimaryFilledButton name="reset" action={reset} />
                        </Space>
                    </Col>
                </Row>
            </Block>
        </PrimaryFilledPanel>
    );
};

export default PositionFiltrePanel;
