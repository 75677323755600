import { DatePicker, Form } from 'antd';
import { useOnChange } from 'component';
import { useExecute } from 'core';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FindScreenCtrl from '../FindScreenCtrl';
import { resultSelector, screenHistorySeclector, screenSeclector } from '../FindScreenMdl';

const DateSelector = () => {
    const [form] = Form.useForm();
    const screen = useSelector(screenSeclector);
    const screeHistory = useSelector(screenHistorySeclector);
    const [availableDates, setAvailableDates] = useState([]);
    const result = useSelector(resultSelector);
    const { execute, success } = useExecute(result);

    const styleMap = {
        online: { backgroundColor: '#52c41a', color: 'white' },
        offline: { backgroundColor: '#faad14', color: 'white' },
        mia: { backgroundColor: '#f5222d', color: 'white' },
        na: { backgroundColor: '#f5222d', color: 'white' },
        disabled: { backgroundColor: '#d9d9d9', color: '#555' },
    };
    useEffect(() => {
        const availableDates = screeHistory.map((item) => ({
            id: item.id,
            hid: item.hid,
            date: dayjs(item.updatedAt),
            status: item.status,
        }));
        setAvailableDates(availableDates);
    }, [screeHistory]);

    const isDateEnabled = (date) => {
        return availableDates.some((availableDate) => availableDate.date.isSame(date, 'day'));
    };

    const getDateStatus = (date) => {
        const found = availableDates.find((availableDate) => availableDate.date.isSame(date, 'day'));
        let status = found ? found.status.toLowerCase() : 'disabled';
        if (status === '') {
            status = 'na';
        }
        return status;
    };

    const disabledDate = (current) => {
        return !isDateEnabled(current);
    };

    const cellRender = (current) => {
        const status = getDateStatus(current);
        const style = styleMap[status];
        return (
            <div className="ant-picker-cell-inner" style={style}>
                {current.date()}
            </div>
        );
    };

    useOnChange('date', form, (date) => {
        const found = availableDates.find((availableDate) => availableDate.date.isSame(date, 'day'));
        if (found?.id) {
            execute(FindScreenCtrl.getScreenByHid, { screenId: found.id, screenHid: found.hid });
        }
    });

    return (
        <Form form={form} initialValues={{ date: dayjs(screen.updatedAt) }}>
            <Form.Item name="date" label="Change Date">
                <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} cellRender={cellRender} />
            </Form.Item>
        </Form>
    );
};

export default DateSelector;
