import { Map, Marker, useMap } from '@vis.gl/react-google-maps';
import { IScreen } from 'model/screen/ScreenDataModel';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { locationListSelector, radiusSelector, screenListSelector } from '../ScreenStatusMapMdl';
import { Circle } from './Circle';
import InfoPopup from './InfoPopup';

const GoogleMaps = () => {
    const screenList = useSelector(screenListSelector);
    const radius = useSelector(radiusSelector);
    const locationList = useSelector(locationListSelector);
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [bounds, setBounds] = useState(null);
    const [markersForScreen, setMarkersForScreen] = useState([]);
    const [markersForLocation, setMarkersForLocation] = useState([]);

    const map = useMap();
    const icons = {
        ON_UNSEL: 'https://maps.gstatic.com/mapfiles/ms2/micons/blue.png',
        ON_SEL: 'https://maps.gstatic.com/mapfiles/ms2/micons/purple-dot.png',
        OFF_UNSEL: 'https://maps.gstatic.com/mapfiles/ms2/micons/red.png',
        OFF_SEL: 'https://maps.gstatic.com/mapfiles/ms2/micons/pink-dot.png',
    };
    const LOC = 'https://maps.gstatic.com/mapfiles/ms2/micons/homegardenbusiness.png';

    useEffect(() => {
        const markerList = screenList.map((screen: IScreen) => {
            return (
                <Marker //
                    key={screen.id}
                    position={screen}
                    onClick={() => setSelectedScreen(screen)}
                    icon={icons[screen.tag]}
                />
            );
        });
        setMarkersForScreen(markerList);
    }, [screenList]);

    const RADIUS_OF_EARTH = 6371000; // Radius of the Earth in meters

    const extendBoundsWithRadius = (bounds, location, radius) => {
        const lat = location.lat * (Math.PI / 180);
        const lng = location.lng * (Math.PI / 180);

        const latChange = radius / RADIUS_OF_EARTH;
        const latNorth = lat + latChange;
        const latSouth = lat - latChange;

        const lngChange = radius / (RADIUS_OF_EARTH * Math.cos(lat));
        const lngEast = lng + lngChange;
        const lngWest = lng - lngChange;

        bounds.extend(new window.google.maps.LatLng(latNorth * (180 / Math.PI), lngEast * (180 / Math.PI)));
        bounds.extend(new window.google.maps.LatLng(latSouth * (180 / Math.PI), lngWest * (180 / Math.PI)));
    };

    useEffect(() => {
        if (locationList?.length > 0) {
            const markers = locationList?.map((location, index) => (
                <Marker //
                    key={index}
                    position={location}
                    icon={LOC}
                />
            ));

            const newBounds = new window.google.maps.LatLngBounds();
            locationList.forEach((location) => {
                extendBoundsWithRadius(newBounds, location, radius); // Extend by radius
            });

            setMarkersForLocation(markers);
            setBounds(newBounds);
        } else {
            setMarkersForLocation([]);
            setBounds(null);
        }
    }, [locationList, radius]);

    const getMarkerList = useCallback(() => {
        const a = [...markersForScreen, ...markersForLocation];
        return a;
    }, [markersForScreen, markersForLocation]);

    useEffect(() => {
        if (bounds) {
            map.fitBounds(bounds);
        }
    }, [bounds]);

    const renderCircles = () => {
        const circleList = locationList.map((location, index) => (
            <Circle //
                key={index}
                center={location}
                radius={radius} // Circle radius in meters
                strokeColor={'#00aaff'}
                strokeOpacity={0.8}
                strokeWeight={2}
                fillColor={'#00aaff'}
                fillOpacity={0.3}
                map={map}
            />
        ));
        return circleList;
    };

    return (
        <Map //
            style={{ width: '100%', height: 'calc(100vh - 80px)' }}
            defaultCenter={{ lat: 50.8, lng: 4.4 }}
            defaultZoom={10}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
        >
            {selectedScreen && <InfoPopup screen={selectedScreen} close={() => setSelectedScreen(null)} />}
            {getMarkerList()}
            {renderCircles()}
        </Map>
    );
};

export default GoogleMaps;
