
export const InventoryI18n = {
    FindScreenPage: 'Inventory',
    inventory: 'Inventory',
    'FindScreenUc.title': 'Find Screens',
    'screen.filter': 'Screen Filter',
    'screen.resultList': 'Query Result - Screen List',
    'screen.selectedList': 'Selected Screen List',
    id: 'Id',
    name: 'Name',
    zipcode: 'Zip Code',
    status: 'Status',
    find: 'Find',
    'viewScreen': 'View',
    'selectScreen': 'Select',
    'removeScreen': 'Remove',
    screenDetail: 'Screen Detail',
    close: 'Close',
    frameNumber: 'Frame Number',
    lat: 'Latitude',
    lng: 'Longitude',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    editQuery: 'Edit Query',
    clearQuery: 'Clear Query',
    massUpdate: 'Mass Update',
    updateDayPart: 'Day Part Mass Update',
    updateName: 'Name Mass Update',
    clusterName: 'Cluster Name',
    oldName: 'Old Name',
    newName: 'New Name',
    confirm: 'Confirm',
    cancel: 'Cancel',
    from: 'From',
    to: 'To',
    weight: 'Weight',
    startDate: 'Start Date',
    endDate: 'End Date',
    geometry: 'Geometry',
    attributes: 'Attributes',
    'tab.info': 'Info',
    'tab.frame': 'Frame',
    'tab.dayPart': 'Day Part',
    pattern: 'Pattern',
    arg1: 'Arg1',
    arg2: 'Arg2',
    arg3: 'Arg3',
    addArg: 'Add Arg',
    apply: 'Apply',
    createCluster: 'Would like to create a cluster with this selection?',
    day: 'Day',
    start: 'Start',
    end: 'End',
    active: 'Active',
    width: 'Width',
    height: 'Height',
    exportScreen: 'Export Screen',
    loop_policy_id: 'Loop Policy',
    selectAll: 'Select All',
    clearAll: 'Clear All',

};
