import { ShareAltOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { BOOST_URL } from 'common/config/constants.config';
import { InfoDialog } from 'component';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { currentClusterSelector } from '../ScreenStatusMapMdl';

const ShareClusterAction = () => {
    const [visible, setVisible] = useState(false);
    const currentCluster = useSelector(currentClusterSelector);
    const close = () => {
        setVisible(false);
    };
    return (
        <Space>
            <PrimaryOutlinedButton
                label="share"
                icon={<ShareAltOutlined />}
                action={() => {
                    setVisible(true);
                }}
            />
            <InfoDialog name="ShareClusterDialog" visible={visible} closeAction={close}>
                <div>Share this link with your team to collaborate on this cluster:</div>
                <a href={`${BOOST_URL}/cluster/${currentCluster.id}/map`} target="_blank">
                    https://www.doohboost.app/cluster/{currentCluster.id}/map
                </a>
            </InfoDialog>
        </Space>
    );
};

export default ShareClusterAction;
