import { Col, Row } from 'antd';
import { Section } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import { useState } from 'react';
import CampaignDetailDialog from './element/CampaignDetailDialog';
import CampaignFilter from './element/CampaignFilter';
import CampaignTable from './element/CampaignTable';
import SelectedCampaignTable from './element/SelectedCampaignTable';

const FindCampaignView = () => {
    const [visible, setVisible] = useState(false);
    const [campaignId, setCampaignId] = useState(false);
    const viewCampaign = (campaign) => {
        setCampaignId(campaign.id);
        setVisible(true);
    };
    //
    return (
        <Section>
            <Row gutter={[20, 20]}>
                <Col span="24">
                    <PrimaryOutlinedPanel title="campaign.filter">
                        <CampaignFilter />
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="16">
                    <CampaignTable viewCampaign={viewCampaign} />
                </Col>
                <Col span="8">
                    <SelectedCampaignTable viewCampaign={viewCampaign} />
                </Col>
            </Row>
            <Row>
                <Col span="4" offset="20">
                    <CampaignDetailDialog visible={visible} setVisible={setVisible} campaignId={campaignId} />
                </Col>
            </Row>
        </Section>
    );
};

export default FindCampaignView;
