import { CheckOutlined, EyeOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import { PrimaryFilledPanel } from 'component';
import InTableViewAction from 'component/action/InTableViewAction';
import { STable } from 'component/table/styles';
import { createSorter } from 'component/table/tableUtils';
import { useAppDispatch } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FindCampaignMdl, campaignListSelector } from '../FindCampaignMdl';

const CampaignTable = ({ viewCampaign }) => {
    const dispatch = useAppDispatch();
    const campaignList = useSelector(campaignListSelector);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

    const selectCampaign = (campaign) => {
        dispatch(FindCampaignMdl.selectCampaign(campaign));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: createSorter('id'),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: createSorter('name'),
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            sorter: createSorter('start_date'),
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            sorter: createSorter('end_date'),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <Space>
                    <InTableViewAction name="viewScreen" icon={<EyeOutlined />} action={() => viewCampaign(record)} />
                    <InTableViewAction name="selectScreen" icon={<CheckOutlined />} action={() => selectCampaign(record)} />
                </Space>
            ),
        },
    ];

    const length = () => {
        return campaignList ? campaignList.length : 0;
    };

    return (
        <Badge.Ribbon text={length()} color="red">
            <PrimaryFilledPanel title="campaign.resultList">
                <STable
                    dataSource={campaignList}
                    rowKey="id"
                    columns={columns}
                    pagination={{
                        ...pagination,
                        total: campaignList?.length,
                        showSizeChanger: false,
                    }}
                    onChange={handleTableChange}
                    bordered //
                />
            </PrimaryFilledPanel>
        </Badge.Ribbon>
    );
};

export default CampaignTable;
