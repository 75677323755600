import { App, Layout } from 'antd';
import { ErrorMap } from 'common/i18n/ErrorMap';
import { LabelMap } from 'common/i18n/LabelMap';
import { MessageMap } from 'common/i18n/MessageMap';
import { Spinner } from 'component';
import ErrorDialog from 'component/dialog/ErrorDialog';
import { I18nMdl, infoDescriptorSelector, useAppDispatch, useI18n } from 'core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router';
import { LayoutContextProvider } from './LayoutContext';
import LayoutFooter from './LayoutFooter';
import LayoutHeader from './LayoutHeader';

const GlobalLayout = () => {
    const { i18nInfo } = useI18n();
    const dispatch = useAppDispatch();

    const { notification } = App.useApp();
    const infoDescriptor = useSelector(infoDescriptorSelector);
    const { pathname } = useLocation();

    useEffect(() => {
        dispatch(I18nMdl.extendLabelMap(LabelMap));
        dispatch(I18nMdl.extendErrorMap(ErrorMap));
        dispatch(I18nMdl.extendMessageMap(MessageMap));
    }, []);

    useEffect(() => {
        const message = i18nInfo(infoDescriptor);
        if (message) {
            notification.success({
                message,
                placement: 'topRight',
            });
        }
    }, [infoDescriptor]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <LayoutContextProvider>
            <Spinner>
                <Layout style={{ overflowY: 'hidden' }}>
                    <LayoutHeader />
                    <Layout.Content style={{ minHeight: '100vh', overflowY: 'hidden' }}>
                        <Outlet />
                    </Layout.Content>
                    <LayoutFooter />
                </Layout>

                <ErrorDialog />
            </Spinner>
        </LayoutContextProvider>
    );
};

export default GlobalLayout;
