import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { IContent } from './ContentDataModel';

const list = async (): Promise<IContent[]> => {
    const response = await axios.get(`${API_URL}/contents`);
    return response.data?.rows;
};


const ContentService = {
    list,
};

export default ContentService;