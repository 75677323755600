import 'asset/style/default.css';
import GlobalLayout from 'common/layout/GlobalLayout';
import permissionMap from 'common/security/permissionMap';
import { theme } from 'common/theme/theme';
import { AppConfigType } from 'core';
import CoreApp from 'core/CoreApp';
import { ReferenceService } from 'module/common/reference/ReferenceService';
import { useMemo } from 'react';
import roleAreaMap from 'roleArea/roleAreaMap';

const App = () => {
    const config: AppConfigType = useMemo(
        () => ({
            appName: 'BOOST',
            locale: 'en',
            dateFormat: 'YYYY-MM-DD',
            formatDateTime: 'YYYY-MM-DD HH:mm',
            apiTimeout: 500000,
            theme: theme,
            permissionMap: permissionMap,
            roleAreaMap: roleAreaMap,
            listReference: ReferenceService.list,
        }),
        []
    );

    return (
        <CoreApp config={config}>
            <GlobalLayout />
        </CoreApp>
    );
};

export default App;
