import { action } from 'core';
import ContentService from 'model/content/ContentService';
import { ListContentReq, ListContentRes } from './ListContentMdl';




const listContentImpl = async (request: ListContentReq, result: ListContentRes, thunkAPI) => {
    result.contentList = await ContentService.list();
};

const ListContentCtrl = {
    listContent: action<ListContentReq, ListContentRes>(listContentImpl, 'ListContentCtrlCtrl/listContent'),
};

export default ListContentCtrl;
