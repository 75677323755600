
export const DoohMapI18n = {
    ScreenStatusMapPage: 'Dooh Map',
    doohMap: 'Dooh Map',
    screen: 'Screen',
    cluster: 'Cluster',
    'delay.filter': 'Delay Filter',
    'offline.filter': 'Offline Filter',
    'status.filter': 'Status Filter',
    clickToAdd: 'Click to add',
    status_last: 'Last Update',
    'screen.list': 'Screen List',
    'cluster.list': 'Cluster List',
    save: 'Save',
    share: 'Share',
    ShareClusterDialog: 'Share Cluster Map',
    close: 'Close',
    select: 'Select',
    unselect: 'Unselect',
    'position.filter': 'Position Filter',
    'positionFilter': 'Apply',
    filterByOne: 'Filter',
    resetFilterByOne: 'Reset',
    addAddress: 'Add Address',
    filter: 'Filter',
    reset: 'Reset',
    loadSelection: 'Load Selection',
    saveSelection: 'Save Selection',
    addToSelection: 'Add To Selection',

};
