import { Badge, Image } from 'antd';
import { useI18n } from 'core';
import styled from 'styled-components';

const RootComponent = styled.div`
    background-color: #fff;
    width: 100%;
    &.enabled {
        cursor: pointer;
        &:hover {
            background-color: ${(props) => `${props.theme.token.colorPrimary}0D`};
        }
    }
    &.disabled {
        cursor: not-allowed;
    }
`;

const Body = styled.div`
    text-align: center;
    padding-top: 38px;
    height: 220px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    &.enabled {
        border: ${(props) => `2px solid ${props.theme.token.colorPrimary}`};
    }
    &.disabled {
        border: ${(props) => `2px solid ${props.theme.token.colorPrimary}55`};
        border-bottom: none;
    }
`;

const Footer = styled.div`
    &.enabled {
        background-color: ${(props) => props.theme.token.colorPrimary};
        color: #fff;
        font-weight: bold;
        padding: 10px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: ${(props) => `2px solid ${props.theme.token.colorPrimary}`};
    }
    &.disabled {
        background-color: ${(props) => `${props.theme.token.colorPrimary}55`};
        color: #fff;
        font-weight: bold;
        padding: 10px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: ${(props) => `2px solid ${props.theme.token.colorPrimary}33`};
    }
`;

const Card = ({ title, image, action = null, badge = null, type = 'normal' }) => {
    const { i18n } = useI18n();
    const colorMap = { high: '#F72585', low: '#7209B7' };
    const getRootComponent = () => {
        return (
            <RootComponent title="doohMap" onClick={action} className={action ? 'enabled' : 'disabled'}>
                <Body className={action ? 'enabled' : 'disabled'}>
                    <Image src={image} width={140} preview={false} />
                </Body>
                <Footer className={action ? 'enabled' : 'disabled'}>{i18n(title)}</Footer>
            </RootComponent>
        );
    };
    if (badge) {
        return (
            <Badge.Ribbon text={badge ? i18n(badge) : null} color={colorMap[type]}>
                {getRootComponent()}
            </Badge.Ribbon>
        );
    }
    return getRootComponent();
};

export default Card;
