import { Space } from 'antd';
import { PrimaryFilledPanel, RowSpacing, Section } from 'component';
import ContentGraph from './element/ContentGraph';
import ContentTable from './element/ContentTable';
import ExportAction from './element/ExportAction';

const ListContentView = () => {
    //
    return (
        <Section>
            <PrimaryFilledPanel title="content.graph">
                <ContentGraph />
            </PrimaryFilledPanel>
            <RowSpacing />
            <PrimaryFilledPanel title="content.list">
                <div style={{ padding: '10px 0' }}>
                    <Space>
                        <ExportAction />
                    </Space>
                </div>
                <ContentTable />
            </PrimaryFilledPanel>
        </Section>
    );
};

export default ListContentView;
