import { List } from 'antd';
import { PrimaryFilledPanel } from 'component';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { screenListSelector } from '../ScreenStatusMapMdl';
import SaveSelectionAction from './SaveSelectionAction';

const ListScreenPanel = () => {
    const screenList = useSelector(screenListSelector);
    const [selectedScreenList, setSelectedScreenList] = useState([]);

    useEffect(() => {
        const x = screenList.filter((screen) => screen.selected);
        setSelectedScreenList(x);
    }, [screenList]);

    return (
        <PrimaryFilledPanel label={selectedScreenList.length + ' Selected Screens '} marge="0">
            {selectedScreenList?.length > 0 && (
                <div style={{ padding: '10px 0', textAlign: 'center' }}>
                    <SaveSelectionAction />
                </div>
            )}
            <div style={{ height: 'calc(100vh - 300px)', overflowY: 'scroll', overflowX: 'hidden' }}>
                <List //
                    bordered
                    dataSource={selectedScreenList}
                    renderItem={(screen, index) => (
                        <List.Item>
                            <List.Item.Meta //
                                title={<span style={{ color: screen.status === 'online' ? '#7209B7' : 'red' }}>{screen.id}</span>}
                                description={screen.zipcode}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </PrimaryFilledPanel>
    );
};

export default ListScreenPanel;
