import { DeleteOutlined } from '@ant-design/icons';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import { useAppDispatch } from 'core';
import { ScreenStatusMapMdl } from '../ScreenStatusMapMdl';

const InitClusterAction = () => {
    const dispatch = useAppDispatch();

    const initCluster = () => {
        dispatch(ScreenStatusMapMdl.initCluster());
    };
    return <PrimaryOutlinedButton label="clear" icon={<DeleteOutlined />} action={initCluster} />;
};

export default InitClusterAction;
/*
  const components = {
        Button: {
            defaultColor: '#fff',
            defaultBg: 'transparent',
            defaultBorderColor: 'transparent',
            defaultHoverColor: 'red',
            defaultHoverBg: '#fff',
            defaultHoverBorderColor: '#fff',
        },
    };
 
    return (
        <ConfigProvider theme={{ components }}>
            <BaseBouton {...props} icon={<DeleteOutlined />} toolTip="CLear Cluster" action={initCluster} />
        </ConfigProvider>
    );
*/
