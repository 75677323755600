import { Form } from 'antd';
import { InfoDialog } from 'component';
import { useExecute } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FindScreenCtrl from '../FindScreenCtrl';
import { resultSelector, screenSeclector } from '../FindScreenMdl';
import DateSelector from './DateSelector';
import ScreenDetailPanel from './ScreenDetailPanel';

const ScreenDetailDialog = ({ visible, setVisible, screenId }) => {
    const [form] = Form.useForm();
    const screen = useSelector(screenSeclector);
    const result = useSelector(resultSelector);
    const { execute } = useExecute(result);
    const [newScreen, setNewScreen] = useState(null);

    useEffect(() => {
        form.resetFields();
        if (screen?.id) {
            form.setFieldsValue(screen);
            setNewScreen(screen);
        }
    }, [screen]);

    useEffect(() => {
        if (screenId && visible) {
            execute(FindScreenCtrl.getScreen, { screenId });
        }
    }, [screenId]);

    const close = () => {
        setVisible(false);
    };

    //
    return (
        <InfoDialog name="screenDetail" label={'Screen Detail At ' + newScreen?.updatedAt} visible={visible} closeAction={close} width={1200}>
            <DateSelector />
            <ScreenDetailPanel form={form} screen={newScreen} />
        </InfoDialog>
    );
};
export default ScreenDetailDialog;
