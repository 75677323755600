

const ALL = [
    'viewScreen',
    'selectScreen',
    'exportScreen',
    'removeScreen',
    'clearAll',
    'selectAll',
];
const ACL_ADMIN = [...ALL,];
const ACL_USER = [...ALL,];

const permissionMap = {
    ADMIN: ACL_ADMIN,
    USERDEFAULT: ACL_USER,
};

export default permissionMap;
