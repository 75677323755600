import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { IMetadata } from './MetadataDataModel';

const get = async (): Promise<IMetadata> => {
    const response = await axios.get(`${API_URL}/metadata`);
    return response.data;
};


const MetadataService = {
    get,
};

export default MetadataService;