import CampaignModule from 'module/booking/definition/BookingModule';
import ContentModule from 'module/content/definition/ContentModule';
import DoohMapModule from 'module/doohMap/definition/DoohMapModule';
import InventoryModule from 'module/inventory/definition/InventoryModule';
import AdminHomeModule from './home';

const AdminModuleList = [
    AdminHomeModule(), //
    DoohMapModule(),
    InventoryModule(),
    CampaignModule(),
    ContentModule(),
];
export default AdminModuleList;
