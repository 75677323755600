import { DeleteOutlined } from '@ant-design/icons';
import { Badge, Space } from 'antd';
import { PrimaryFilledPanel, RowSpacing } from 'component';
import InTableViewAction from 'component/action/InTableViewAction';
import FilledButton from 'component/button/FilledButton';
import { STable } from 'component/table/styles';
import { createSorter } from 'component/table/tableUtils';
import { useAppDispatch } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FindScreenMdl, screenListSelector, selectedScreenListSelector } from '../FindScreenMdl';
import ExportScreenDialog from './ExportScreenDialog';

const SelectedScreenTable = ({ viewScreen }) => {
    const dispatch = useAppDispatch();
    const selectedScreenList = useSelector(selectedScreenListSelector);
    const screenList = useSelector(screenListSelector);
    const [exportScreenVisible, setExportScreenVisible] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 100 });

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };

    const removeScreen = (screen) => {
        dispatch(FindScreenMdl.removeScreen(screen));
    };
    const clearAll = () => {
        dispatch(FindScreenMdl.clearAll());
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: createSorter('name'),
        },
        {
            title: 'Zipcode',
            dataIndex: 'zipcode',
            key: 'zipcode',
            sorter: createSorter('zipcode'),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => <InTableViewAction name="removeScreen" icon={<DeleteOutlined />} action={() => removeScreen(record)} />,
        },
    ];
    return (
        <Badge.Ribbon text={selectedScreenList?.length} color="red">
            <PrimaryFilledPanel title="screen.selectedList">
                <div style={{ textAlign: 'right', padding: '10px 0' }}>
                    <Space>
                        <FilledButton action={clearAll} name="clearAll" inactive={selectedScreenList?.length > 0 ? null : 'No selected screens'} />
                        <FilledButton action={() => setExportScreenVisible(true)} name="exportScreen" inactive={selectedScreenList?.length > 0 ? null : 'No selected screens'} />
                    </Space>
                </div>
                <STable
                    dataSource={selectedScreenList}
                    rowKey="id"
                    columns={columns}
                    pagination={{
                        ...pagination,
                        total: selectedScreenList?.length,
                        showSizeChanger: false, // Disable changing page size
                    }}
                    onChange={handleTableChange}
                    bordered //
                ></STable>
                <RowSpacing />
                <ExportScreenDialog visible={exportScreenVisible} setVisible={setExportScreenVisible} />
            </PrimaryFilledPanel>
        </Badge.Ribbon>
    );
};

export default SelectedScreenTable;
