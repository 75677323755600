import { List } from 'antd';

const ListScreenPanel = ({ cluster }: { cluster: any }) => {
    return (
        <div style={{ position: 'fixed', top: 50, right: 50, width: '300px', overflowY: 'scroll', height: 'calc(100vh - 200px)', backgroundColor: '#FFFFFFdd' }}>
            <List //
                bordered
                dataSource={cluster.screenList}
                header={<div style={{ fontWeight: '700', fontSize: '18px' }}>{cluster.name + ' : ' + cluster.screenList.length + ' Screens '}</div>}
                renderItem={(screen: any) => (
                    <List.Item>
                        <List.Item.Meta //
                            title={<span style={{ color: screen.status === 'online' ? '#7209B7' : 'red' }}>{screen.status}</span>}
                        />
                        {screen.name}
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ListScreenPanel;
