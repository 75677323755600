import WarningFilledButton from 'component/button/WarningFilledButton';
import _ from 'lodash';
import { unparse } from 'papaparse';
import React from 'react';
import { useSelector } from 'react-redux';
import { contentListSelector } from '../ListContentMdl';

const ExportAction: React.FC = () => {
    const contentList = useSelector(contentListSelector);

    const exportData = () => {
        const keysToRemove = ['metadata', 'DomainId'];

        const filteredContentList = contentList.map((item: any) => _.omit(item, keysToRemove));

        const csv = unparse(filteredContentList);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        link.setAttribute('download', `content-${timestamp}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return <WarningFilledButton name="export" action={exportData} />;
};

export default ExportAction;
