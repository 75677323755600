import { Line } from '@ant-design/charts';
import { InfoDialog } from 'component';
import { useExecute } from 'core';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FindCampaignCtrl from '../FindCampaignCtrl';
import { reportDataSelector } from '../FindCampaignMdl';

const ViewReportDialog = ({ visible, setVisible }) => {
    const reportData = useSelector(reportDataSelector);
    const { execute, success } = useExecute();

    const close = () => {
        setVisible(false);
    };

    useEffect(() => {
        if (visible) {
            execute(FindCampaignCtrl.viewReport);
        }
    }, [visible]);

    const getGraph = useCallback(() => {
        if (reportData?.length < 1) return null;
        const formattedData = [];
        reportData.forEach((item) => {
            formattedData.push({ date: item.played_on, value: item.plays, type: 'plays' });
            formattedData.push({ date: item.played_on, value: item.imps, type: 'imps' });
        });
        const config = {
            data: formattedData,
            xField: 'date',
            yField: 'value',
            colorField: 'type',
            yAxis: {
                label: {
                    formatter: (v) => `${v}`,
                },
            },
            slider: {
                x: {},
            },
            legend: {
                color: {
                    layout: {
                        justifyContent: 'center',
                    },
                },
            },
            smooth: true,
            scale: { color: { range: ['red', 'blue'] } },
            style: {
                lineWidth: 2,
            },
        };
        return <Line {...config} />;
    }, [reportData]);

    return (
        <InfoDialog name="viewReport" visible={visible} closeAction={close} width={1200}>
            {getGraph()}
        </InfoDialog>
    );
};

export default ViewReportDialog;
